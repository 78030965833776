// Core
import { createApp, App as VueApp } from "vue";
import router from "./router";
import App from "./App.vue";

// Libraries
import "bootstrap";
import drag from "v-drag";
import { createPinia } from "pinia";
import VueRewards from "vue-rewards";
import ElementPlus from "element-plus";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { createHead } from "@vueuse/head";
import VueDragscroll from "vue-dragscroll";
import { MotionPlugin } from "@vueuse/motion";
import VueDatePicker from "@vuepic/vue-datepicker";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";

// Services & Helpers
import { initToast } from "@/plugins/toast";
import { vFocus } from "./directives/focus";
import { projectAuth } from "./firebase/config";
import { initInlineSvg } from "@/plugins/inline-svg";
import { PtBr } from "./helpers/element-plus/lang-ptbr";
import { initializeAnalytics } from "./core/services/analyticsService";

// Assets
import "nprogress/nprogress.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/sass/components/_toast.scss";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    fbq: (type: string, event: string) => void;
    clarity: ((set: string, key: string, value: string) => void) & ((event: string, value: string) => void);
  }
}

let app: VueApp | undefined;
let head;

export const pinia = createPinia();

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    head = createHead();

    app.config.errorHandler = (error, vm, info) => {
      console.log(error, vm, info);
    };

    app.use(pinia.use(piniaPluginPersistedstate));
    app.use(head);
    app.use(router);
    app.use(drag, {});
    app.use(VueRewards);
    app.use(PrimeVue, {
      theme: {
        preset: Aura,
        options: {
          darkModeSelector: ".copybase-dark"
        }
      }
    });
    app.use(MotionPlugin);
    app.use(VueDragscroll);
    app.use(VueInputAutowidth);
    app.use(autoAnimatePlugin);
    app.use(ElementPlus, { locale: PtBr });

    app.component("VueDatePicker", VueDatePicker);

    app.directive("focus", vFocus);

    initInlineSvg(app);
    initToast(app);

    initializeAnalytics();

    app.mount("#app");
  }
});
