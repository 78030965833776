// Core
import { createRouter, createWebHistory, NavigationGuard, RouteLocationRaw, RouteRecordRaw } from "vue-router";

// Libraries
import { pinia } from "@/main";
import NProgress from "nprogress";
// import { setUser as sentrySetUser } from "@sentry/vue";

// Services & Helpers
import { analyticsSetUser } from "@/core/services/analyticsService";

// Composables
import { useUser, isUserTeamMember } from "@/composables/useUser";

// Stores
import { useUserStore } from "@/store/user";
import { useTeamStore } from "@/store/team";

// Types
import { UserParamsT } from "@/@types/user";

const { updateStatus } = useUser();

const requireAuth: NavigationGuard = async (to, _from, next) => {
  const userStore = useUserStore(pinia);

  const user = userStore.token;

  await updateStatus();

  if (userStore.userInfos == null) {
    userStore.token = userStore.userId = userStore.name = "";
  }

  if (!user) {
    next({ name: "login" });
  } else {
    if (userStore.userInfos && to.fullPath !== "/sign-up" && to.fullPath !== "/login") {
      analyticsSetUser(userStore.userInfos);

      // Habilita o Sentry apenas em produção
      // if (import.meta.env.VITE_NODE_ENV !== "development")
      //   sentrySetUser({
      //     email: userStore.userInfos.email,
      //     id: userStore.userId,
      //     team: userStore.userInfos.activeTeam?.name,
      //     teamId: userStore.userInfos.activeTeam?.id
      //   });
    }

    next();
  }
};

const requireInvite: NavigationGuard = async (to, _from, next) => {
  await updateStatus();

  const { userStatus } = useUser();

  const { inviteId, email, token } = to.query as UserParamsT;

  const logged = inviteId ? inviteId && email === userStatus.value.email : userStatus.value.id;

  if (!logged) return next();

  const onboarded = userStatus.value.onboarding.hasCompleted || userStatus.value.onboarding.hasCompletedStep2;

  if (onboarded) {
    const dashboard: RouteLocationRaw = { name: "dashboard" };

    if (token) dashboard.query = { token };

    next(dashboard);
  }

  const step = userStatus.value.onboarding.currentStep;

  if (step === 6) return next("subscription");

  if (step === 5) return next("meetbase-onboarding");

  next();
};

const requireAdmin: NavigationGuard = async (_to, _from, next) => {
  const { userStatus } = useUser();

  if (userStatus.value?.role !== "admin") {
    next({ name: "dashboard" });
  } else {
    next();
  }
};

const requirePlan: NavigationGuard = async (to, _from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamIsLeadMachineOnly) {
    if ((to.name as string)?.includes("leads")) {
      next();
    } else {
      next({ name: "leads" });
    }
  } else {
    next();
  }
};

const requireOnboarding: NavigationGuard = async (_, __, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamIsLeadMachineOnly) return next();

  const { userStatus } = useUser();

  const onboarding = userStatus.value?.onboarding;
  const completed = onboarding?.hasCompleted || onboarding?.hasCompletedStep2;

  if (completed) return next();

  const step = onboarding?.currentStep;

  if (step === 6) return next("subscription");

  if (step === 5) return next("meetbase-onboarding");

  return next("sign-up");
};

const requireTeamAdmin: NavigationGuard = (_to, _from, next) => {
  const teamStore = useTeamStore(pinia);

  if (
    teamStore.isPlanInvalid ||
    teamStore.isSubscriptionLate ||
    (!isUserTeamMember.value && !teamStore.isPlanInvalid && !teamStore.isSubscriptionLate)
  ) {
    next();
  } else {
    next("/dashboard");
  }
};

const requireValidSubscription: NavigationGuard = (_to, _from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.isPlanInvalid || teamStore.isSubscriptionLate) {
    next({ name: "Plans" });
  } else {
    next();
  }
};

const requireMeetbase: NavigationGuard = (_to, from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamHasMeetbase) {
    next();
  } else {
    next(from);
  }
};

const requireLeadMachine: NavigationGuard = (_to, from, next) => {
  const teamStore = useTeamStore(pinia);

  if (teamStore.teamHasLeadMachine) {
    next();
  } else {
    next(from);
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/components/layout/LayoutComponent.vue"),
    beforeEnter: [requireAuth, requireOnboarding, requirePlan],
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/HomeView.vue")
      },
      {
        path: "/gerador/chat/:id?",
        name: "chat",
        props: true,
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/ChatView.vue")
      },
      {
        path: "/gerador-v2/:id",
        name: "gennai2",
        props: true,
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/GennaiView.vue")
      },
      {
        path: "/modelos",
        name: "modelos",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/ModelsView.vue")
      },
      {
        path: "/projeto/",
        name: "create-project",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/project/ProjectCreation.vue")
      },
      {
        path: "/planos/",
        name: "Plans",
        beforeEnter: [requireTeamAdmin],
        component: () => import("@/views/PricingTable.vue")
      },
      {
        path: "/conta",
        name: "account",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/account/AccountView.vue"),
        children: [
          {
            path: "config",
            name: "account-settings",
            beforeEnter: [requireValidSubscription],
            component: () => import("@/views/account/SettingsView.vue")
          }
        ]
      },
      {
        path: "/documentos/",
        name: "Documentos",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/docbase/DocumentsList.vue")
      },
      {
        path: "/transcricoes/",
        name: "transcriptions",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/transcription/TranscriptionsList.vue")
      },
      {
        path: "/videos/:vslId/edit/:documentId",
        name: "videos",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/videos/EditTextVSL.vue")
      },
      {
        path: "/escritor/:id?/:chatId?",
        name: "escritor-no-doc",
        props: true,
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/docbase/DocbaseView.vue")
      },
      {
        path: "/gerador/vsl/",
        name: "gerador-vsl",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/videos/NewVideo.vue")
      },
      {
        path: "/gerador/artigo/",
        name: "gerador-artigo",
        props: true,
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/docbase/NewDocument.vue")
      },
      {
        path: "/gerador/imagem/",
        name: "gerador-imagem",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/images/ImagesGeneratorView.vue")
      },
      {
        path: "/gerador/transcrever-video",
        name: "transcrever-video",
        props: true,
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/transcription/VideoTranscriber.vue")
      },
      {
        path: "/atualizar-projeto/",
        name: "update-project",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/project/UpdateProject.vue")
      },
      {
        path: "/a63f438ec472b454321925691dff72b7",
        name: "admin",
        beforeEnter: [requireAdmin],
        component: () => import("@/views/admin/AdminView.vue")
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/error/NotFoundView.vue")
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/error/ServerErrorView.vue")
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404"
      },
      {
        path: "/equipe/:id",
        name: "team",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/team/TeamView.vue")
      },
      {
        path: "/equipe/:id/projetos/",
        name: "project-list",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/project/ProjectList.vue")
      },
      {
        path: "/equipe/:id/projetos/historico",
        name: "folders-list",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/project/FoldersView.vue")
      },
      {
        path: "/equipe/:id/projetos/favoritos",
        name: "folders-list-favorite",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/project/FoldersView.vue")
      },
      {
        path: "/universidade",
        name: "universidade",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/university/UniversityView.vue")
      },
      {
        path: "/universidade/:category",
        name: "universidade-category",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/university/UniversityCategory.vue")
      },
      {
        path: "/universidade/:category/:video",
        name: "universidade-video",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/university/UniversityVideo.vue")
      },
      {
        path: "/transcrever-call",
        name: "transcrever-call",
        beforeEnter: [requireValidSubscription, requireMeetbase],
        component: () => import("@/views/meetbase/SubmitFile.vue")
      },
      {
        path: "/gravacoes",
        name: "calls-list",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/meetbase/CallsList.vue")
      },
      {
        path: "/resultado-gravacao/:id",
        name: "call-result",
        beforeEnter: [requireValidSubscription, requireMeetbase],
        component: () => import("@/views/meetbase/CallResult.vue")
      },
      {
        path: "/maquina-de-leads",
        name: "leads-machine",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/LeadsRobotView.vue")
      },
      {
        path: "/listas-de-leads",
        name: "leads",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/LeadsListing.vue")
      },
      {
        path: "/listas-de-leads/:listId/:leadId?",
        name: "leads-list",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/LeadsList.vue")
      },
      {
        path: "/automacoes",
        name: "automations",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/AutomationsListing.vue")
      },
      {
        path: "/automacoes-historico",
        name: "automations-history",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/AutomationsHistory.vue")
      },
      {
        path: "/vendedores",
        name: "sellers",
        beforeEnter: [requireValidSubscription, requireLeadMachine],
        component: () => import("@/views/leads/SellersListing.vue")
      },
      {
        path: "/integracoes",
        name: "integrations",
        beforeEnter: [requireValidSubscription],
        component: () => import("@/views/integrations/IntegrationsListing.vue")
      }
    ]
  },
  {
    path: "/",
    component: () => import("@/views/auth/AuthView.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        beforeEnter: [requireInvite],
        component: () => import("@/views/auth/LoginView.vue")
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue")
      }
    ]
  },
  {
    path: "/sign-up",
    name: "onboarding-cadastro",
    beforeEnter: [requireInvite],
    component: () => import("@/views/auth/SignUpOnboarding.vue")
  },
  {
    path: "/subscription",
    name: "subscription",
    beforeEnter: [requireAuth],
    component: () => import("@/views/auth/SubscriptionView.vue")
  },
  {
    path: "/meetbase-onboarding",
    name: "meetbase-onboarding",
    beforeEnter: [requireAuth],
    component: () => import("@/views/meetbase/AddMeetbase.vue")
  },
  {
    path: "/trial-iniciado",
    name: "trial-iniciado",
    beforeEnter: [requireAuth],
    component: () => import("@/views/project/TrialStarted.vue")
  },
  {
    path: "/resultado-gravacao-publica/:id",
    name: "public-call-result",
    component: () => import("@/views/meetbase/PublicView.vue")
  },
  {
    path: "/",
    beforeEnter: [requireAuth],
    component: () => import("@/views/auth/AuthView.vue"),
    children: [
      {
        path: "/google-auth",
        name: "google-auth",
        component: () => import("@/views/auth/GoogleAuth.vue")
      },
      {
        path: "/instrucoes-meetbase",
        name: "meetbase-instructions",
        component: () => import("@/views/meetbase/MeetbaseHelp.vue")
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach(() => {
  NProgress.start();
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
